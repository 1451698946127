import {
  Club,
  ClubDetails,
  ClubPdf,
  ExcludeReq,
  LeaveClubReq,
  RetreatReq,
} from '@src/models/club';
import {api} from './client';
import {Pagination, PaginationParams} from '@src/models/pagination';
import {Place} from '@src/common/inputs/GSearch';
import {Membership} from '@src/models/membership';
import {useAuthStore} from '@src/stores/auth-store';
import {Application} from '@src/models/application';
import {DeepPartial, parseNullArr} from '@src/models/deep-partial';
import {FileObj} from '@src/models/file';
import {User} from '@src/models/user';

export namespace ClubApi {
  interface GetAllClubsParams extends PaginationParams {
    longitude?: string;
    latitude?: string;
    search?: string;
    language?: string;
    categoryId?: string;
    subCategoryId?: string;
    subSubCategoryId?: string;
    searchArea?: number;
    sort_by?:
      | 'distance_DESC'
      | 'distance_ASC'
      | 'createdAt_DESC'
      | 'createdAt_ASC';
  }

  export const getAll = async (
    params: GetAllClubsParams
  ): Promise<Pagination<Club>> => {
    const token = useAuthStore.getState().token;
    const res = await api.get<Pagination<Club>>(
      'companies/all' + `${token ? '' : '/unauthorized'}`,
      {
        params: {
          latitude: params.latitude || undefined,
          longitude: params.longitude || undefined,
          mainCategory:
            params.categoryId !== 'ALL' ? params.categoryId : undefined,
          subCategory:
            params.subCategoryId !== 'ALL' ? params.subCategoryId : undefined,
          subSubCategory:
            params.subSubCategoryId !== 'ALL'
              ? params.subSubCategoryId
              : undefined,
          search: params.search || undefined,
          language: params.language || undefined,
          searchArea: params.searchArea || undefined,
          limit: params.limit || undefined,
          page: params.page || undefined,
          sort_by: params.sort_by || undefined,
        },
      }
    );
    return res.data;
  };

  export const getDetails = async (clubId: string) => {
    const token = useAuthStore.getState().token;
    const res = await api.get<ClubDetails>(
      `companies/${clubId}` + `${token ? '' : '/unauthorized'}`
    );
    return res.data;
  };

  export interface AddClubReq {
    name: string;
    email: string;
    companyEmail: string;
    companyPhone: string;
    shortName: string;
    website: string;
    instagram: string;
    phone: string;
    foundationDate: Date | string;
    /** '5'? */
    maxMembers: number;
    language: 'EN' | 'DE' | 'PL';
    payingTaxes: boolean;
    /** '12'? */
    taxesPercent: string;
    taxId: string;
    IBAN: string;
    legalForm: 'UNSPECIFIED';
    /** @example ['some description']; */
    currency: 'GBP';
    address: Place;
    categories: {
      categoryId: string;
      subcategoryId?: string;
      subsubcategoryId?: string;
      description: string;
    }[];
  }
  export interface EditClubReq {
    name?: string;
    bankBic: string;
    email?: string;
    companyEmail?: string;
    secondEmail?: string;
    companyPhone?: string;
    memberplaceEmail?: string;
    memberplacePhone?: string;
    isPrivate?: boolean;
    shortName?: string | null;
    website?: string | null;
    instagram?: string | null;
    twitter?: string | null;
    facebook?: string | null;
    phone?: string;
    uid?: string;

    foundationDate?: Date | string | null;
    /** '5'? */
    maxMembers?: string;
    language?: string;
    payingTaxes?: boolean;
    /** '12'? */
    taxesPercent?: string;
    taxId?: string;
    IBAN?: string;
    legalForm?: string;
    lawForm?: string;
    /** @example ['some description']; */
    currency?: string;
    address?: {
      city: string;
      zipCode: string;
      country: string;
      address: string;
      latitude: string;
      longitude: string;
    };
    // categories?: {
    //   categoryId: string;
    //   subcategoryId?: string;
    //   subsubcategoryId?: string;
    //   description: string;
    // }[];
  }

  export const addClub = async (data: AddClubReq) => {
    const res = await api.post('companies/new-company', data);
    return res.data;
  };

  interface getClubsWhereUserIsMemberCompanyParams extends PaginationParams {
    county?: string;
    city?: string;
    clubName?: string;
    language?: string;
    role?: 'FAN';
  }
  export const getClubsWhereUserIsMember = async (
    params: getClubsWhereUserIsMemberCompanyParams
  ) => {
    const res = await api.get<Pagination<Club>>('companies/user-companies', {
      params: {
        county: params.county || undefined,
        city: params.city || undefined,
        language: params.language || undefined,
        clubName: params.clubName || undefined,
        role: params.role || undefined,
        page: params.page,
        limit: params.limit ?? undefined,
      },
    });
    return res.data;
  };

  interface GetClubDetailsQuery {
    clubId: string;
  }
  export const getClubDetails = async (
    query: GetClubDetailsQuery
  ): Promise<ClubDetails> => {
    const res = await api.get<DeepPartial<ClubDetails>>(
      `companies/${query.clubId}`,
      {}
    );

    function parseFile(f?: DeepPartial<FileObj> | undefined | null): FileObj {
      return {
        compressFileId: f?.compressFileId ?? '',
        createdAt: f?.createdAt ?? '',
        customName: f?.customName ?? ' ',
        description: f?.description ?? '',
        id: f?.id ?? '',
        isCompress: f?.isCompress ?? false,
        modifiedAt: f?.modifiedAt ?? '',
        order: f?.order ?? -100,
        originalname: f?.originalname ?? '',
        type: f?.type ?? '',
        visibleToAll: f?.visibleToAll ?? false,
        url: f?.url ?? '',
      };
    }

    function parseFileArr(
      fileArr?: (DeepPartial<FileObj> | undefined | null)[] | undefined | null
    ): FileObj[] {
      return parseNullArr(fileArr).map(parseFile);
    }

    function parseClubUser(item?: DeepPartial<User> | undefined | null) {
      const user: User = {
        userId: item?.userId ?? '',
        id: item?.id ?? '',
        name: item?.name ?? '',
        surname: item?.surname ?? '',
        createdAt: item?.createdAt ?? '',
        modifiedAt: item?.modifiedAt ?? '',
        userPhone: item?.userPhone ?? '',
        allowSms: item?.allowSms ?? false,
        phoneNumberVerified: item?.phoneNumberVerified ?? false,
        userLanguages: (item?.userLanguages as string[]) ?? [],
        email: item?.email ?? '',
        role: item?.role ?? 'USER',
        salutationId: item?.salutationId ?? '',
        address: {
          address: item?.address?.address ?? '',
          city: item?.address?.city ?? '',
          country: item?.address?.country ?? '',
          countryCode: item?.address?.countryCode ?? '',
          createdAt: item?.address?.createdAt ?? '',
          id: item?.address?.id ?? '',
          lat: item?.address?.lat ?? -1,
          lng: item?.address?.lng ?? -1,
          modifiedAt: item?.address?.modifiedAt ?? '',
          zipCode: item?.address?.zipCode ?? '',
        },
        status: item?.status ?? '',
        gender: item?.gender ?? '',
        mobilePhone: item?.mobilePhone ?? '',
        privatePhone: item?.privatePhone ?? '',
        workPhone: item?.workPhone ?? '',
        birthDate: item?.birthDate ?? '',
        clubsAmount: item?.clubsAmount ?? '',
        lastPayment: item?.lastPayment ?? '',
        secondName: item?.secondName ?? '',
        familyName: item?.familyName ?? '',
        companyName: item?.companyName ?? '',
        receiptType: item?.receiptType ?? '',
        titleId: item?.titleId ?? '',
        secondEmail: item?.secondEmail ?? '',
        membership: {
          canVote: item?.membership?.canVote ?? false,
          autoGeneratedBill: item?.membership?.autoGeneratedBill ?? false,
          currency: item?.membership?.currency ?? undefined,
          picked: item?.membership?.picked ?? false,
          id: item?.membership?.id ?? '',
          name: item?.membership?.name ?? '',
          paymentPeriod: item?.membership?.paymentPeriod ?? '',
          price: item?.membership?.price ?? '',
          contributionId: item?.membership?.contributionId ?? '',
          // @ts-ignore
          company: item?.membership?.company || undefined,
        },
        files: parseFileArr(item?.files),
        avatar: Array.isArray(item?.avatar)
          ? parseFileArr(item?.avatar)
          : parseFile(item?.avatar),
        inContactList: item?.inContactList ?? false,
        salutation: {id: item?.salutation?.id ?? ''},
        title: {id: item?.title?.id ?? ''},
        subscription: item?.subscription
          ? {
              id: item.subscription?.id ?? '',
              name: item.subscription?.name ?? '',
              period: item.subscription?.period ?? '',
              price: item.subscription?.price ?? '',
              permissions: item.subscription?.permissions ?? '',
              purchaseDate: item.subscription?.purchaseDate ?? '',
              expirationDate: item.subscription?.expirationDate ?? '',
            }
          : null,
      };
      return user;
    }

    const item: ClubDetails = {
      isPrivate: res.data.isPrivate ?? false,
      bankBic: res.data.bankBic ?? '',
      newsAmountThisMonth: res.data.newsAmountThisMonth ?? -1,
      id: res.data.id || 'INVALID_ID',
      contactPersonId: res.data.contactPersonId || '',
      companyName: res.data.companyName || '',
      companyShortName: res.data.companyShortName || '',
      name: res.data.name ?? '',
      myUserCompany: {
        roles: parseNullArr(res.data.myUserCompany?.roles),
        isVisible: res.data.myUserCompany?.isVisible ?? false,
        id: res.data.myUserCompany?.id ?? '',
        createdAt: res.data.myUserCompany?.createdAt ?? '',
        modifiedAt: res.data.myUserCompany?.modifiedAt ?? '',
        companyName: res.data.myUserCompany?.companyName ?? '',
        president: {
          id: res.data.myUserCompany?.president?.id ?? '',
          createdAt: res.data.myUserCompany?.president?.createdAt ?? '',
          modifiedAt: res.data.myUserCompany?.president?.modifiedAt ?? '',
          name: res.data.myUserCompany?.president?.name ?? '',
          surname: res.data.myUserCompany?.president?.surname ?? '',
        },
        vicePresident: null,
        membership: {
          canVote: res.data.myUserCompany?.membership?.canVote ?? false,
          autoGeneratedBill:
            res.data.myUserCompany?.membership?.autoGeneratedBill ?? false,
          currency: res.data.myUserCompany?.membership?.currency ?? undefined,
          picked: res.data.myUserCompany?.membership?.picked ?? false,
          id: res.data.myUserCompany?.membership?.id ?? '',
          name: res.data.myUserCompany?.membership?.name ?? '',
          paymentPeriod:
            res.data.myUserCompany?.membership?.paymentPeriod ?? '',
          price: res.data.myUserCompany?.membership?.price ?? '',
          contributionId:
            res.data.myUserCompany?.membership?.contributionId ?? '',
          // @ts-ignore
          company: res.data.myUserCompany?.membership?.company || undefined,
        },
        name: res.data.myUserCompany?.name ?? '',
        surname: res.data.myUserCompany?.surname ?? '',
        currentMembersNumber:
          res.data.myUserCompany?.currentMembersNumber ?? -1,
      },
      categories: parseNullArr(res.data.categories).map((item) => ({
        id: item.id ?? '',
        createdAt: item.createdAt ?? '',
        modifiedAt: item.modifiedAt ?? '',
        description: item.description ?? '',
        category: {
          id: item.category?.id ?? '',
          createdAt: item.category?.createdAt ?? '',
          modifiedAt: item.category?.modifiedAt ?? '',
          name: item.category?.name ?? '',
        },
        subcategory: item.subcategory
          ? {
              id: item.subcategory?.id ?? '',
              createdAt: item.subcategory?.createdAt ?? '',
              modifiedAt: item.subcategory?.modifiedAt ?? '',
              name: item.subcategory?.name ?? '',
            }
          : null,
        subSubcategory: item.subSubcategory
          ? {
              id: item.subSubcategory?.id ?? '',
              createdAt: item.subSubcategory?.createdAt ?? '',
              modifiedAt: item.subSubcategory?.modifiedAt ?? '',
              name: item.subSubcategory?.name ?? '',
            }
          : null,
      })),
      foundationDate: res.data.foundationDate || '',
      role: res.data.role || undefined,
      companyPhone: res.data.companyPhone ?? '',
      allowSms: res.data.allowSms ?? false,
      phone: res.data.phone ?? '',
      email: res.data.email || '',
      emailVerified: res.data.emailVerified ?? false,
      numberOfMember: res.data.numberOfMember ?? -1,
      applicationSent: res.data.applicationSent ?? false,
      descriptions: parseNullArr(res.data.descriptions),
      owner: {
        id: res.data.owner?.id ?? '',
        name: res.data.owner?.name ?? '',
      },
      viceOwner: parseClubUser(res.data?.viceOwner),
      presidentUserCompany: parseClubUser(res.data.presidentUserCompany),
      disapplicationSent: res.data.disapplicationSent ?? false,
      disaplicationId: res.data.disaplicationId ?? undefined,
      legalForm: res.data.legalForm ?? undefined,
      payingTaxes: res.data.payingTaxes ?? false,
      taxId: res.data.taxId ?? undefined,
      taxesPercent: res.data.taxesPercent ?? '',
      disaplication: {
        departureDate: res.data.disaplication?.departureDate ?? '',
        id: res.data.disaplication?.id ?? '',
        reason: {
          createdAt: res.data.disaplication?.reason?.createdAt ?? '',
          id: res.data.disaplication?.reason?.createdAt ?? '',
          modifiedAt: res.data.disaplication?.reason?.createdAt ?? '',
          name: res.data.disaplication?.reason?.createdAt ?? '',
        },
        status: res.data.disaplication?.status ?? '',
        userCompany: {
          allowSms: res.data?.disaplication?.userCompany?.allowSms ?? false,
          companyApplications: null,
          createdAt: res.data?.disaplication?.userCompany?.createdAt ?? '',
          modifiedAt: res.data?.disaplication?.userCompany?.modifiedAt ?? '',
          disaplication: undefined,
          details: {
            canVote:
              res.data.disaplication?.userCompany?.details?.canVote ?? false,
            isContactPerson:
              res.data.disaplication?.userCompany?.details?.isContactPerson ??
              false,
            isVisible:
              res.data.disaplication?.userCompany?.details?.isVisible ?? false,
            roles: parseNullArr(
              res.data.disaplication?.userCompany?.details?.roles
            ),
          },
          email: res.data?.disaplication?.userCompany?.email ?? '',
          files: parseFileArr(res.data?.disaplication?.userCompany?.files),
          id: res.data?.disaplication?.userCompany?.id ?? '',
          avatar: Array.isArray(res.data?.disaplication?.userCompany?.avatar)
            ? parseFileArr(res.data?.disaplication?.userCompany?.avatar)
            : parseFile(res.data?.disaplication?.userCompany?.avatar),
          lang: res.data?.disaplication?.userCompany?.lang ?? '',
          name: res.data?.disaplication?.userCompany?.name ?? '',
          phoneNumberVerified:
            res.data?.disaplication?.userCompany?.phoneNumberVerified ?? false,
          surname: res.data?.disaplication?.userCompany?.surname ?? '',
          userPhone: res.data?.disaplication?.userCompany?.userPhone ?? '',
          user: undefined,
          role: res.data?.disaplication?.userCompany?.role ?? '',
          status: res.data?.disaplication?.userCompany?.status ?? '',
          type: res.data?.disaplication?.userCompany?.type ?? '',
          requestedMembership: undefined,
          userId: res.data?.disaplication?.userCompany?.userId ?? '',
        },
      },
      address: {
        id: res.data.address?.id ?? '',
        name: res.data.address?.name ?? '',
        city: res.data.address?.city ?? '',
        adress: res.data.address?.adress ?? '',
        zipCode: res.data.address?.zipCode ?? '',
        country: res.data.address?.country ?? '',
      },
      secondSubcategory: {
        id: res.data.secondSubcategory?.id ?? '',
        name: res.data.secondSubcategory?.name ?? '',
      },
      subcategory: {
        id: res.data.subcategory?.id ?? '',
        name: res.data.subcategory?.name ?? '',
      },
      myCompanyApplication: {
        id: res.data?.myCompanyApplication?.id ?? '',
        status: res.data?.myCompanyApplication?.status ?? '',
        role: res.data?.myCompanyApplication?.role ?? 'MEMBER',
        type: res.data?.myCompanyApplication?.type ?? '',
      },
      events: res.data.events || [],
      files: parseFileArr(res.data.files),
      website: res.data.website ?? '',
      companyEmail: res.data.companyEmail ?? '',
      IBAN: res.data.IBAN ?? '',
      createdAt: res.data.createdAt ?? '',
      maxMembers: res.data.maxMembers ?? '',
      language: res.data.language ?? '',
      facebook: res.data.facebook ?? '',
      twitter: res.data.twitter ?? '',
      instagram: res.data.instagram ?? '',
      wallet: res.data.wallet
        ? {
            amountInCHF: res.data.wallet.amountInCHF ?? '',
            amountInEUR: res.data.wallet.amountInEUR ?? '',
            amountInGBP: res.data.wallet.amountInGBP ?? '',
            amountInUSD: res.data.wallet.amountInUSD ?? '',
            id: res.data.wallet.id ?? '',
          }
        : undefined,
      subscription: res.data.subscription
        ? {
            id: res.data.subscription?.id ?? '',
            name: res.data.subscription?.name ?? '',
            period: res.data.subscription?.period ?? '',
            price: res.data.subscription?.price ?? '',
            permissions: res.data.subscription?.permissions ?? '',
            purchaseDate: res.data.subscription?.purchaseDate ?? '',
            expirationDate: res.data.subscription?.expirationDate ?? '',
          }
        : null,
    };
    return item;
  };

  export const getClubDetailsMembersForOwner = async (
    clubId: string,
    interests: Membership[] | null,
    search: string,
    activeMember: boolean,
    page: number,
    limit?: number
  ) => {
    let url = `?activeMember=${activeMember}`;
    interests?.forEach((interest, index) => {
      url += `&membershipsIds[${index}]=${interest.id}`;
    });
    if (search !== '') {
      url += `&search=${search}`;
    }
    url += `&page=${page}&limit=${limit ? limit : '15'}`;
    const res = await api.get(`companies/users/owner/${clubId}` + url, {});
    return res.data;
  };
  export const getCompanyApplications = async (
    clubId: string,
    status: string,
    sorting: string,
    search: string,
    page?: number
  ) => {
    const res = await api.get(`company-application/company/${clubId}`, {
      params: {
        status: status || undefined,
        sort_by: sorting
          ? `surname_${sorting.includes('ASC') ? 'ASC' : 'DESC'}`
          : undefined,
        query: search || undefined,
        page: page || undefined,
      },
    });

    return res.data;
  };
  export const sendVerificationEmailAgain = async (clubId: string) => {
    const res = await api.put('companies/send-activation-token/' + clubId);
    return res.data;
  };
  export const acceptApplication = async (applicationId: string) => {
    const res = await api.put('company-application/accept/' + applicationId);
    return res.data;
  };
  export const acceptAskedForAddApplication = async (
    applicationId: string,
    membershipId: string
  ) => {
    const res = await api.put('company-application/accept/' + applicationId, {
      membershipId,
    });
    return res.data;
  };
  export const rejectApplication = async (applicationId: string) => {
    const res = await api.put('company-application/reject/' + applicationId);
    return res.data;
  };

  export interface ChangeVisibilityInCompanyReq {
    companyId: string;
    userId: string;
    isVisible: boolean;
  }

  export const changeVisibilityInCompany = async (
    body: ChangeVisibilityInCompanyReq
  ) => {
    const res = await api.put('companies/hide/change-visibility', body);
    return res.data;
  };

  export interface InvitationToExistingUserReq {
    email: string;
    companyId: string;
    membershipCategoryId: string;
  }
  export const sendInvitationToExistingUser = async (
    body: InvitationToExistingUserReq
  ) => {
    const res = await api.post(`companies/send-invitation-to-company`, body);
    return res.data;
  };
  export interface InvitationToNewUserReq {
    companyId: string;
    name: string;
    surname: string;
    secondName?: string;
    privatePhone?: string;
    workPhone?: string;
    secondEmail?: string;
    email: string;
    mobilePhone: string;
    birthDate: string;
    titleId: string;
    gender: string;
    userLanguages: string[];
    salutationId?: string;
    address: Place;
    membershipCategoryId: string;
  }
  export const sendInvitationToNewUser = async (
    body: InvitationToNewUserReq
  ) => {
    const res = await api.post(`companies/add-to-company`, {
      ...body,
      titleId: body.titleId || undefined,
    });
    return res.data;
  };
  export interface JoinClubReq {
    companyId: string;
    membershipId?: string;
    askingForAdd?: boolean;
    requestedRole: 'MEMBER' | 'CONTACT_PERSON' | 'TREASURER' | 'VICE_PRESIDENT';
  }

  export const joinClub = async (data: JoinClubReq) => {
    const res = await api.post('company-application/send-application', data);
    return res.data;
  };
  export const rejectClubApplication = async (id: string) => {
    const res = await api.put('company-application/retreat-as-user/' + id);
    return res.data;
  };

  interface ReportClubReq {
    description: string;
  }

  export const reportClub = async (id: string, data: ReportClubReq) => {
    const res = await api.post(`companies/${id}/report`, data);
    return res.data;
  };

  export const getAllClubMemberships = async (id: string) => {
    const res = await api.get('companies/memberships/all/' + id);
    return res.data;
  };
  export const editClub = async (clubId: string, data: EditClubReq) => {
    await api.put(`companies/${clubId}`, data);
  };
  export interface CompanyLeaveReason {
    id: string;
    name: string;
  }
  export const companyLeaveReasons = async () => {
    const res = await api.get('companies/company-disapplication/reasons');
    return res.data;
  };

  export const excludeFromCompany = async (body: ExcludeReq) => {
    const res = await api.put(`companies/remove-from-company`, body);
    return res.data;
  };
  export const leaveCompany = async (body: LeaveClubReq) => {
    const {clubId, ...obj} = body;
    const res = await api.post(
      `companies/${clubId}/company-disapplication`,
      obj
    );
    return res.data;
  };
  export const retreatDissaplication = async (body: RetreatReq) => {
    const res = await api.put(
      `companies/${body.clubId}/company-disapplication/${body.dissaplicationId}/retreat`
    );
    return res.data;
  };
  export const getCompanyDissaplications = async (
    clubId: string,
    search: string,
    sorting: string,
    page?: number
  ) => {
    const res = await api.get(`companies/${clubId}/company-disapplication`, {
      params: {
        search: search || undefined,
        page: page || undefined,
        sort_by:
          sorting === 'NAME_ASC'
            ? 'surname_ASC'
            : sorting === 'NAME_DESC'
            ? 'surname_DESC'
            : undefined,
      },
    });
    return res.data;
  };
  export const approveDissaplication = async (
    clubId: string,
    dissaplicationId: string,
    date: string
  ) => {
    const res = await api.put(
      `companies/${clubId}/company-disapplication/${dissaplicationId}/approve`,
      {departureDate: date}
    );
    return res.data;
  };

  export interface UpdateClubCategoryReq {
    subcategoryId?: string;
    subsubcategoryId?: string;
    description: string;
  }

  export const updateClubCategory = async (
    clubId: string,
    clubCategoryId: string,
    body: UpdateClubCategoryReq
  ) => {
    await api.put(`companies/${clubId}/categories/${clubCategoryId}`, body);
  };

  export interface GetClubsThatUserHaveAppliedToParams {
    search: string;
    page: number;
  }

  export const getClubsThatUserHaveAppliedTo = async (
    params: GetClubsThatUserHaveAppliedToParams
  ): Promise<Pagination<Application>> => {
    const result = await api.get<Pagination<Application>>(
      'company-application/user',
      {
        params: {
          page: params.page || undefined,
          search: params.search || undefined,
          status: 'PENDING',
          type: 'USER_REQUEST',
        },
      }
    );

    return result.data;
  };

  export const uploadClubPdf = async (clubId: string, file: File) => {
    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('customName', file.name);
    await api.post(`companies/${clubId}/pdf`, formData);
  };

  export const deleteClubPdf = async (clubId: string, fileId: string) => {
    await api.delete(`companies/${clubId}/pdf/${fileId}`);
  };

  export const getClubPdfs = async (
    clubId: string,
    params?: PaginationParams
  ): Promise<Pagination<ClubPdf>> => {
    const res = await api.get<Pagination<ClubPdf>>(
      `companies/${clubId}/files`,
      {
        params: {...params, fileType: 'COMPANY_PDF'},
      }
    );
    return res.data;
  };
}
