export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]> | null;
    }
  : T;

export function parseNullArr<T>(
  arr?: (T | undefined | null)[] | undefined | null
): T[] {
  if (!arr) {
    return [];
  }

  return arr.filter(Boolean) as T[];
}
